import React from 'react'

const MassMailer = () => {
  return (
    <>
    <div className="min-h-screen bg-slate-100 dark:bg-gray-950 flex items-center justify-center flex-col py-20">
        MassMailer
        
    </div>
    </>
  )
}

export default MassMailer