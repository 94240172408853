const config = { 

    BASE_URL: 'http://localhost:8081'
    
   
    // BASE_URL: "https://server-beta-lovat-71.vercel.app"

    
    // BASE_URL: ' https://server-8dy1.onrender.com'
    // BASE_URL: "https://server-production-3601.up.railway.app"
};

export default config;
